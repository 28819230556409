<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <v-layout wrap justify-center>
      <v-flex xs12 py-10>
        <span
          style="font-family: poppinsbold; color: black; letter-spacing: 1px"
          :style="{
            'font-size': $vuetify.breakpoint.name == 'xl' ? '32px' : '28px',
          }"
          >Gallery: Industry Safety Consulting</span
        >
      </v-flex>
    </v-layout>
    <!-- <v-layout wrap justify-center>
      <v-flex xs12 v-if="category.bgfSafetyConsultingDetails"> -->
    <!-- <template v-if="category"> -->
    <!-- <viewer
          :images="category.bgfSafetyConsultingDetails.topContent.sliderImages"
          :key="category.bgfSafetyConsultingDetails.topContent.sliderImages"
        > -->
    <!-- <masonry
              :cols="{ default: 4, 1000: 3, 700: 1, 400: 1 }"
              :gutter="{ default: '30px', 700: '15px' }"
            > -->
    <!-- <span
            v-for="item in category.bgfSafetyConsultingDetails.topContent
              .sliderImages"
            :key="item"
            class="pa-2"
          >
            <img
              :src="mediaURL + item"
              eager
              :lazy-src="mediaURL + item"
              :alt="item"
              style="object-fit: cover"
              height="300px"
              width="300px"
            />
          </span> -->
    <!-- <template v-slot:placeholder>
                    <ImageLoader />
                  </template>
                </v-img> -->
    <!-- </masonry> -->
    <!-- </viewer> -->
    <!-- <div ref="scrollMarker"></div> -->
    <!-- </template> -->
    <!-- </v-flex>
    </v-layout> -->
    <v-layout wrap justify-center  >
      <v-flex xs12 pa-2>
        <template v-if="slider.length > 0">
          <masonry
            :cols="{ default: 4, 1000: 3, 700: 1, 400: 1 }"
            :gutter="{ default: '30px', 700: '15px' }"
          >
            <div
              class="scene scene--card pb-7"
              v-for="(item, i) in slider"
              :key="i"
            >
        <!-- <viewer :images="slider"> -->
          <!-- <masonry
              :cols="{ default: 4, 1000: 3, 700: 1, 400: 1 }"
              :gutter="{ default: '30px', 700: '15px' }"
            > -->
          <!-- <span v-for="(item, i) in slider" :key="i" class="pa-2"> -->
            <v-img
              :src="mediaURL + item.image"
              eager
              :lazy-src="mediaURL + item.image"
              :alt="item.name"
              contain
            >
            <template v-slot:placeholder>
                  <ImageLoader />
                </template>
            </v-img>
            <!-- <template v-slot:placeholder>
                  <ImageLoader />
                </template>
              </v-img> -->
          <!-- </span>
        </viewer> -->
        </div>
          </masonry>
        <div ref="scrollMarker"></div>
        </template>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showMore: false,
      limit: 10,
      pages: 0,
      page: 1,
      currentPage: 1,
      totalData: 0,
      totalPage: 0,
      category: "",
      slider: [],
      ids: [],
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  beforeMount() {
    this.getData();
    this.getMore();
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/category/view",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.category = response.data.data;
          this.courses = response.data.courses;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getMore() {
      this.appLoading = true;
      axios({
        url: "/category/slider/list/gallery",
        method: "POST",
        data: {
          page: 1,
          limit: 10,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.slider = response.data.data;
          this.pages = response.data.pages;
          this.ids = response.data.ids;
          this.totalPage = Math.ceil(response.data.totalLength / this.limit);
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getList() {
      // this.appLoading = true;
      axios({
        url: "/category/slider/list/gallery",
        method: "POST",
        data: {
          page: this.page,
          limit: 10,
          ids: this.ids,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          //   this.slider = response.data.data;
          this.slider = this.slider.concat(response.data.data);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    handleScroll() {
      const scrollMarker = this.$refs.scrollMarker;
      const markerTop = scrollMarker.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      // const windowHeight = 1000

      if (markerTop <= windowHeight) {
        // if (!this.isLoading ) {
        this.page++;
        if (this.page <= this.totalPage) this.getList();
      }
    },
  },
};
</script>